import * as React from "react"
import * as ReactDOM from "react"
import type { HeadFC } from "gatsby"
import Layout from "../components/layout"
import '../styles/global.css'

const pageStyles = {
    color: "#232129",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
  }


const IndexPage = () => {
    return (
      <main className="bg-white" style={pageStyles}>
        <Layout>
          <div className="lg:flex md:grid ">
            <div className="shadow-2xl bg-blue-600 max-w-sm rounded-2xl overflow-hidden m-2 border-double border-4 border-green-600">
              <Card
                id="1" 
               img="/images/a4.jpeg" alt=""
               name="Sara"
               description=" Lorem ipsum dolor sit amet, consectetur adipisicing
               elit. Voluptatibus quia, nulla! Maiores et perferendis
               eaque, exercitationem praesentium nihil."
               url= "subscription1"
               />
            </div>
            <div className="shadow-2xl bg-yellow-500  max-w-sm rounded-2xl overflow-hidden m-2 border-double border-4 border-red-600">
                <Card 
                id="2" 
               img="/images/a4.jpeg" alt=""
               name="Linda"
               description=" Lorem ipsum dolor sit amet, consectetur adipisicing
               elit. Voluptatibus quia, nulla! Maiores et perferendis
               eaque, exercitationem praesentium nihil."
               url= "subscription2"
               />
            </div>
            <div className="shadow-2xl  bg-green-600  max-w-sm rounded-2xl overflow-hidden m-2 border-double border-4 border-blue-600">
                <Card 
                id="3" 
               img="/images/a4.jpeg" alt=""
               name="Karen"
               description=" Lorem ipsum dolor sit amet, consectetur adipisicing
               elit. Voluptatibus quia, nulla! Maiores et perferendis
               eaque, exercitationem praesentium nihil."
               url= "subscription3"
               />
            </div>
            <div className=" shadow-2xl bg-red-600  max-w-sm rounded-2xl overflow-hidden m-2 border-double border-4 border-yellow-600">
                <Card 
               id="4" 
               img="/images/a4.jpeg" alt=""
               name="Flor"
               description=" Lorem ipsum dolor sit amet, consectetur adipisicing
               elit. Voluptatibus quia, nulla! Maiores et perferendis
               eaque, exercitationem praesentium nihil."
               url= "subscription4"
               />
            </div>
          </div>
               
        </Layout>
      </main>
    )
    
    }

    function Card(props: any){
      return(
        
       /* <div className="shadow-xl bg-yellow-600 max-w-sm rounded-2xl overflow-hidden m-2 " >*/
       <div className="">
            <img src={props.img} alt="" className=" mix-blend-normal p-10 h-full w-full rounded-full"/>
            <div className=" text-white px-5 py-2 rounded-tr-[100px]">
              <div className="font-bold text-4xl underline">{props.name}</div>
              <p className="prose prose-slate pt-3 text-align: justify">
              {props.description}
              </p>
              
              <div className="flex justify-center pt-8 pb-2 ">

                <button id="" type="submit" className=" shadow bg-blue-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-12 rounded">
                  <a href={props.url}>Donación</a>

                </button>
              </div>
            </div>
      </div>
      )
    }
    

export default IndexPage

export const Head: HeadFC = () => <title>El Amor Te Elige A Ti</title>;
